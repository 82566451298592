var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("Driver Registration Form")])]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.DriverList}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)],1)]),(_vm.driver.profile)?_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Personal Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('FirstName'),
                      'is-required': _vm.isFieldRequired('FirstName'),
                    },attrs:{"label":"First Name"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"FirstName",attrs:{"placeholder":"First Name","name":"FirstName"},model:{value:(_vm.driver.firstName),callback:function ($$v) {_vm.$set(_vm.driver, "firstName", $$v)},expression:"driver.firstName"}}),(_vm.errors.has('FirstName'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("FirstName")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('LastName'),
                      'is-required': _vm.isFieldRequired('LastName'),
                    },attrs:{"label":"Last Name"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"LastName",attrs:{"placeholder":"Last Name","name":"LastName"},model:{value:(_vm.driver.lastName),callback:function ($$v) {_vm.$set(_vm.driver, "lastName", $$v)},expression:"driver.lastName"}}),(_vm.errors.has('LastName'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("LastName")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Email'),
                      'is-required': _vm.isFieldRequired('Email'),
                    },attrs:{"label":"Email"}},[_c('el-input',{key:"Email",attrs:{"placeholder":"Email","name":"Email"},model:{value:(_vm.driver.email),callback:function ($$v) {_vm.$set(_vm.driver, "email", $$v)},expression:"driver.email"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Mobile Number'),
                      'is-required': _vm.isFieldRequired('Mobile Number'),
                    },attrs:{"label":"Mobile Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|mobileNo'),expression:"'required|mobileNo'"}],key:"Mobile Number",attrs:{"placeholder":"Mobile Number","maxlength":"10","name":"Mobile Number"},model:{value:(_vm.driver.driver.mobileNo),callback:function ($$v) {_vm.$set(_vm.driver.driver, "mobileNo", $$v)},expression:"driver.driver.mobileNo"}}),(_vm.errors.has('Mobile Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Mobile Number")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('IdentityMark'),
                      'is-required': _vm.isFieldRequired('IdentityMark'),
                    },attrs:{"label":"Identity Mark(Hulia)"}},[_c('el-input',{key:"IdentityMark",attrs:{"placeholder":"Identity Mark","name":"IdentityMark"},model:{value:(_vm.driver.driver.identityMark),callback:function ($$v) {_vm.$set(_vm.driver.driver, "identityMark", $$v)},expression:"driver.driver.identityMark"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Date of Birth'),
                      'is-required': _vm.isFieldRequired('Date of Birth'),
                    },attrs:{"label":"Date of Birth"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Date of Birth",attrs:{"type":"date","placeholder":"Birth Date","name":"Date of Birth"},on:{"change":_vm.bsDateChange},model:{value:(_vm.driver.profile.dobBs),callback:function ($$v) {_vm.$set(_vm.driver.profile, "dobBs", $$v)},expression:"driver.profile.dobBs"}}),_c('br'),(_vm.errors.has('Date of Birth'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Date of Birth")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Nationality'),
                      'is-required': _vm.isFieldRequired('Nationality'),
                    },attrs:{"label":"Nationality"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Nationality",attrs:{"placeholder":"Nationality","name":"Nationality"},model:{value:(_vm.driver.profile.nationality),callback:function ($$v) {_vm.$set(_vm.driver.profile, "nationality", $$v)},expression:"driver.profile.nationality"}}),(_vm.errors.has('Nationality'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Nationality")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Gender'),
                      'is-required': _vm.isFieldRequired('Gender'),
                    },attrs:{"label":"Gender"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Gender",attrs:{"placeholder":"Select Gender","name":"Gender","filterable":""},model:{value:(_vm.driver.profile.gender),callback:function ($$v) {_vm.$set(_vm.driver.profile, "gender", $$v)},expression:"driver.profile.gender"}},_vm._l((_vm.gender),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Gender'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Gender")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Blood Group'),
                      'is-required': _vm.isFieldRequired('Blood Group'),
                    },attrs:{"label":"Blood Group"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Blood Group",attrs:{"placeholder":"Select Blood Group","name":"Blood Group","filterable":""},model:{value:(_vm.driver.driver.bloodGroup),callback:function ($$v) {_vm.$set(_vm.driver.driver, "bloodGroup", $$v)},expression:"driver.driver.bloodGroup"}},_vm._l((_vm.bloodGroup),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Blood Group'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Blood Group")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Vehicle'),
                      'is-required': _vm.isFieldRequired('Vehicle'),
                    },attrs:{"label":"Vehicle"}},[_c('el-select',{key:"Vehicle",attrs:{"placeholder":"Select Vehicle","name":"Vehicle","filterable":""},model:{value:(_vm.driver.driver.defaultVehicle),callback:function ($$v) {_vm.$set(_vm.driver.driver, "defaultVehicle", $$v)},expression:"driver.driver.defaultVehicle"}},_vm._l((_vm.ownerVehicleList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.vehicleNumber,"value":item.id}})}),1)],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Citizenship Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Citizenship Number'),
                      'is-required': _vm.isFieldRequired('Citizenship Number'),
                    },attrs:{"label":"Citizenship Id"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Citizenship Number",attrs:{"placeholder":"Citizenship Id","name":"Citizenship Number"},model:{value:(_vm.driver.profile.citizenshipPassportNo),callback:function ($$v) {_vm.$set(_vm.driver.profile, "citizenshipPassportNo", $$v)},expression:"driver.profile.citizenshipPassportNo"}}),(_vm.errors.has('Citizenship Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Citizenship Number")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Issuing District'),
                      'is-required': _vm.isFieldRequired('Issuing District'),
                    },attrs:{"label":"Issuing District"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Issuing District",attrs:{"placeholder":"Select District","name":"Issuing District","filterable":""},model:{value:(_vm.driver.profile.citizenshipIssuingDistrict),callback:function ($$v) {_vm.$set(_vm.driver.profile, "citizenshipIssuingDistrict", $$v)},expression:"driver.profile.citizenshipIssuingDistrict"}},_vm._l((_vm.districtList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('District'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("District")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Issuing Date'),
                      'is-required': _vm.isFieldRequired('Issuing Date'),
                    },attrs:{"label":"Issuing Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Issuing Date",attrs:{"type":"date","placeholder":"Date","name":"Issuing Date"},on:{"change":_vm.bsDateChange1},model:{value:(_vm.driver.profile.citizenshipIssuingDateBs),callback:function ($$v) {_vm.$set(_vm.driver.profile, "citizenshipIssuingDateBs", $$v)},expression:"driver.profile.citizenshipIssuingDateBs"}}),_c('br'),(_vm.errors.has('Issuing Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Issuing Date")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Permanent Address ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Province'),
                      'is-required': _vm.isFieldRequired('Province'),
                    },attrs:{"label":"Province"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Province",attrs:{"placeholder":"Select Province","name":"Province","filterable":""},on:{"change":_vm.onProvinceChange},model:{value:(_vm.driver.profile.province),callback:function ($$v) {_vm.$set(_vm.driver.profile, "province", $$v)},expression:"driver.profile.province"}},_vm._l((_vm.province),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Province'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Province")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('District'),
                      'is-required': _vm.isFieldRequired('District'),
                    },attrs:{"label":"District"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"District",attrs:{"placeholder":"Select District","name":"District","filterable":""},on:{"change":_vm.onDistrictChange},model:{value:(_vm.driver.profile.district),callback:function ($$v) {_vm.$set(_vm.driver.profile, "district", $$v)},expression:"driver.profile.district"}},_vm._l((_vm.districts),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('District'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("District")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Palika'),
                      'is-required': _vm.isFieldRequired('Palika'),
                    },attrs:{"label":"Municipality"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Palika",attrs:{"placeholder":"Select Palika","name":"Palika","filterable":""},model:{value:(_vm.driver.profile.palika),callback:function ($$v) {_vm.$set(_vm.driver.profile, "palika", $$v)},expression:"driver.profile.palika"}},_vm._l((_vm.municipality),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Palika'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Palika")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Ward Number'),
                      'is-required': _vm.isFieldRequired('Ward Number'),
                    },attrs:{"label":"Ward Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Ward Number",attrs:{"placeholder":"Ward Number","name":"Ward Number","maxlength":"2"},model:{value:(_vm.driver.profile.wardNo),callback:function ($$v) {_vm.$set(_vm.driver.profile, "wardNo", $$v)},expression:"driver.profile.wardNo"}}),(_vm.errors.has('Ward Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Ward Number")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Tole'),
                      'is-required': _vm.isFieldRequired('Tole'),
                    },attrs:{"label":"Tole/Street"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Tole",attrs:{"placeholder":"Tole/Street","name":"Tole"},model:{value:(_vm.driver.profile.tole),callback:function ($$v) {_vm.$set(_vm.driver.profile, "tole", $$v)},expression:"driver.profile.tole"}}),(_vm.errors.has('Tole'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Tole")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Block Number'),
                      'is-required': _vm.isFieldRequired('Block Number'),
                    },attrs:{"label":"Block Number"}},[_c('el-input',{key:"Block Number",attrs:{"placeholder":"Block Number","name":"Block Number"},model:{value:(_vm.driver.profile.blockNo),callback:function ($$v) {_vm.$set(_vm.driver.profile, "blockNo", $$v)},expression:"driver.profile.blockNo"}})],1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-checkbox',{on:{"change":_vm.atChecked},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("Click to copy permanent address to temporary address")])],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Temporary Address ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Province'),
                      'is-required': _vm.isFieldRequired('Province'),
                    },attrs:{"label":"Province"}},[_c('el-select',{key:"Province",attrs:{"placeholder":"Select Province","name":"Province","filterable":""},on:{"change":_vm.onProvinceChange},model:{value:(_vm.driver.profile.currentProvince),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentProvince", $$v)},expression:"driver.profile.currentProvince"}},_vm._l((_vm.province),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Province'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Province")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('District'),
                      'is-required': _vm.isFieldRequired('District'),
                    },attrs:{"label":"District"}},[_c('el-select',{key:"District",attrs:{"placeholder":"Select District","name":"District","filterable":""},on:{"change":_vm.onDistrictChange},model:{value:(_vm.driver.profile.currentDistrict),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentDistrict", $$v)},expression:"driver.profile.currentDistrict"}},_vm._l((_vm.districts),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('District'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("District")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Palika'),
                      'is-required': _vm.isFieldRequired('Palika'),
                    },attrs:{"label":"Municipality"}},[_c('el-select',{key:"Palika",attrs:{"placeholder":"Select Palika","name":"Palika","filterable":""},model:{value:(_vm.driver.profile.currentPalika),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentPalika", $$v)},expression:"driver.profile.currentPalika"}},_vm._l((_vm.municipality),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Palika'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Palika")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Ward Number'),
                      'is-required': _vm.isFieldRequired('Ward Number'),
                    },attrs:{"label":"Ward Number"}},[_c('el-input',{key:"Ward Number",attrs:{"placeholder":"Ward Number","name":"Ward Number","maxlength":"2"},model:{value:(_vm.driver.profile.currentWardNo),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentWardNo", $$v)},expression:"driver.profile.currentWardNo"}}),(_vm.errors.has('Ward Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Ward Number")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Tole'),
                      'is-required': _vm.isFieldRequired('Tole'),
                    },attrs:{"label":"Tole/Street"}},[_c('el-input',{key:"Tole",attrs:{"placeholder":"Tole/Street","name":"Tole"},model:{value:(_vm.driver.profile.currentTole),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentTole", $$v)},expression:"driver.profile.currentTole"}}),(_vm.errors.has('Tole'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Tole")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('BlockNumber'),
                      'is-required': _vm.isFieldRequired('BlockNumber'),
                    },attrs:{"label":"Block Number"}},[_c('el-input',{key:"BlockNumber",attrs:{"placeholder":"Block Number","name":"BlockNumber"},model:{value:(_vm.driver.profile.currentBlockNo),callback:function ($$v) {_vm.$set(_vm.driver.profile, "currentBlockNo", $$v)},expression:"driver.profile.currentBlockNo"}}),(_vm.errors.has('BlockNumber'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("BlockNumber")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"5px"}},[_vm._v(" License Details ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('License Number'),
                      'is-required': _vm.isFieldRequired('License Number'),
                    },attrs:{"label":"License Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"License Number",attrs:{"placeholder":"License Number","name":"License Number"},model:{value:(_vm.driver.driver.licenseNo),callback:function ($$v) {_vm.$set(_vm.driver.driver, "licenseNo", $$v)},expression:"driver.driver.licenseNo"}}),(_vm.errors.has('License Number'))?_c('small',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("License Number"))+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Categories"}},[_c('el-checkbox-group',{model:{value:(_vm.categoryList),callback:function ($$v) {_vm.categoryList=$$v},expression:"categoryList"}},_vm._l((_vm.vehicleTypeGroup),function(item,k){return _c('el-checkbox',{key:k,attrs:{"label":item.id,"value":item.id}},[_vm._v(_vm._s(item.code))])}),1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Issuing Date'),
                      'is-required': _vm.isFieldRequired('Issuing Date'),
                    },attrs:{"label":"Issuing Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Issuing Date",attrs:{"type":"date","placeholder":"Date","name":"Issuing Date"},on:{"change":_vm.bsDateChange2},model:{value:(_vm.driver.driver.licenseDetails[0].issuedDateBs),callback:function ($$v) {_vm.$set(_vm.driver.driver.licenseDetails[0], "issuedDateBs", $$v)},expression:"driver.driver.licenseDetails[0].issuedDateBs"}}),_c('br'),(_vm.errors.has('Issuing Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Issuing Date")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Expire Date'),
                      'is-required': _vm.isFieldRequired('Expire Date'),
                    },attrs:{"label":"Expire Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Expire Date",attrs:{"type":"date","placeholder":"Date","name":"Expire Date"},on:{"change":_vm.bsDateChange3},model:{value:(_vm.driver.driver.licenseDetails[0].expiryDateBs),callback:function ($$v) {_vm.$set(_vm.driver.driver.licenseDetails[0], "expiryDateBs", $$v)},expression:"driver.driver.licenseDetails[0].expiryDateBs"}}),_c('br'),(_vm.errors.has('Expire Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Expire Date")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('IssuingOffice'),
                      'is-required': _vm.isFieldRequired('IssuingOffice'),
                    },attrs:{"label":"Issuing Office"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"IssuingOffice",attrs:{"placeholder":"Issuing Office","name":"IssuingOffice"},model:{value:(_vm.driver.driver.licenseDetails[0].issuingOffice),callback:function ($$v) {_vm.$set(_vm.driver.driver.licenseDetails[0], "issuingOffice", $$v)},expression:"driver.driver.licenseDetails[0].issuingOffice"}}),(_vm.errors.has('IssuingOffice'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("IssuingOffice")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Province'),
                      'is-required': _vm.isFieldRequired('Province'),
                    },attrs:{"label":"Province"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:"Province",attrs:{"placeholder":"Select Province","name":"Province","filterable":""},model:{value:(_vm.driver.driver.licenseDetails[0].province),callback:function ($$v) {_vm.$set(_vm.driver.driver.licenseDetails[0], "province", $$v)},expression:"driver.driver.licenseDetails[0].province"}},_vm._l((_vm.province),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Province'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Province")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Suportive Documents ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Photo'),
                      'is-required': _vm.isFieldRequired('Photo'),
                    },attrs:{"label":"Photo"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.photoId,"accept":"image/*","thumbnail-mode":""}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],key:"Photo",attrs:{"slot":"trigger","name":"Photo","size":"small","type":"primary","round":""},slot:"trigger",model:{value:(_vm.photoId),callback:function ($$v) {_vm.photoId=$$v},expression:"photoId"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(!_vm.driver.profile.photoId && !_vm.errors.has('Photo'))?_c('small',{staticClass:"error",staticStyle:{"font-weight":"bold"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e(),(_vm.errors.has('Photo'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Photo")))]):_vm._e(),_c('detail-tag',{attrs:{"data":_vm.driver.profile.photoIdName,"dataLink":_vm.driver.profile.photoId,"type":"editimage"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('License'),
                      'is-required': _vm.isFieldRequired('License'),
                    },attrs:{"label":"License Front"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange1,"on-remove":_vm.handleRemove1,"file-list":_vm.licenseFront,"accept":"image/*","thumbnail-mode":""}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],key:"License",attrs:{"slot":"trigger","name":"License","size":"small","type":"primary","round":""},slot:"trigger",model:{value:(_vm.licenseFront),callback:function ($$v) {_vm.licenseFront=$$v},expression:"licenseFront"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(
                        !_vm.driver.driver.licenseFront && !_vm.errors.has('License')
                      )?_c('small',{staticClass:"error",staticStyle:{"font-weight":"bold"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e(),(_vm.errors.has('License'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("License")))]):_vm._e(),_c('detail-tag',{attrs:{"data":_vm.driver.driver.licenseFrontName,"dataLink":_vm.driver.driver.licenseFront,"type":"editimage"}})],1)],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }