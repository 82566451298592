




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
} from "@/store/modules";
import driverStore from "@/store/modules/driver";
import { District, Palika } from "@/store/models/meta";
import helpers from "@/utils/helpers";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import { BSToAD } from "bikram-sambat-js";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  photoId: File[] = [];
  licenseFront: File[] = [];
  licenseBack: File[] = [];
  categoryList: number[] = [];
  checked: boolean = false;
  async created() {
    let vm = this;
    await metaStore.loadProvince();
    await metaStore.loadGender();
    await metaStore.loadDis();
    await metaStore.loadBloodGroup();
    await metaStore.loadVehicleTypeCodeDD();
    await metaStore.loadVehicleByOwnerListDD();
    const id = vm.$route.query["id"];

    if (id) {
      vm.isCreate = false;

      await driverStore.getDriverById(id);
      this.categoryList = this.driver.driver.licenseDetails[0].category;
    } else {
      driverStore.resetField();
    }

    this.driver.driver.licenseDetails[0].category = this.categoryList;

    if (this.driver.profile.province) {
      this.districts = await metaStore.loadDistrict(
        this.driver.profile.province
      );
    }

    if (this.driver.profile.district) {
      this.municipality = await metaStore.loadMuncipality(
        this.driver.profile.district
      );
    }
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.driver.profile.dob = bsAD;
      this.driver.profile.dobBs = e;
    }
  }
  bsDateChange1(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.driver.profile.citizenshipIssuingDate = bsAD;
      this.driver.profile.citizenshipIssuingDateBs = e;
    }
  }
  bsDateChange2(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.driver.driver.licenseDetails[0].issuedDate = bsAD;
      this.driver.driver.licenseDetails[0].issuedDateBs = e;
    }
  }
  bsDateChange3(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.driver.driver.licenseDetails[0].expiryDate = bsAD;
      this.driver.driver.licenseDetails[0].expiryDateBs = e;
    }
  }

  get isFileRequired() {
    if (this.$route.query.id) {
      return false;
    }
    return true;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get ownerVehicleList() {
    return metaStore.vehicleList;
  }

  get driver() {
    let b = driverStore.driver;
    console.log(b);
    return driverStore.driver;
  }

  get profile() {
    return driverStore.profile;
  }

  get vehicleTypeGroup() {
    return metaStore.vehicleTypeCodeList;
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.photoId = [file.raw];
      vm.driver.profile.photoId = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.photoId = [];
      vm.driver.profile.photoId = null;
    }
  }
  handleRemove(file: any, fileList: any) {
    let vm = this;
    this.photoId = [];
    vm.driver.profile.photoId = null;
  }
  handleChange1(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.licenseFront = [file.raw];
      vm.driver.driver.licenseFront = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.licenseFront = [];
      vm.driver.driver.licenseFront = null;
    }
  }
  handleRemove1(file: any, fileList: any) {
    let vm = this;
    this.licenseFront = [];
    vm.driver.driver.licenseFront = null;
  }
  handleChange2(file: any, fileList: any) {
    let vm = this;
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.licenseBack = [file.raw];
      vm.driver.driver.licenseBack = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.licenseBack = [];
      vm.driver.driver.licenseBack = null;
    }
  }
  handleRemove2(file: any, fileList: any) {
    let vm = this;
    this.licenseBack = [];
    vm.driver.driver.licenseBack = null;
  }

  addLicenseDetails() {
    this.driver.driver.licenseDetails.push({
      issuedDate: "",
      issuedDateBs: "",
      expiryDate: "",
      expiryDateBs: "",
      category: [],
      issuingOffice: "",
      province: "",
    });
  }

  deleteLicenseDetails(counter: any) {
    this.driver.driver.licenseDetails.splice(counter, 1);
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.driver.id) {
      // Remove File Fields without file
      helpers.removeFilesForPatch([
        { property: this.driver.profile, fieldName: "photoId" },
        { property: this.driver.driver, fieldName: "licenseFront" },
        { property: this.driver.driver, fieldName: "licenseBack" },
      ]);
    }
    this.driver.driver.licenseDetails[0].category = this.categoryList;
    await driverStore.createDriver();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.DriverList);
  }

  atChecked() {
    let vm = this;
    vm.driver.profile.currentProvince = vm.driver.profile.province;
    vm.driver.profile.currentDistrict = vm.driver.profile.district;
    vm.driver.profile.currentPalika = vm.driver.profile.palika;
    vm.driver.profile.currentWardNo = vm.driver.profile.wardNo;
    vm.driver.profile.currentTole = vm.driver.profile.tole;
    vm.driver.profile.currentBlockNo = vm.driver.profile.blockNo;
  }

  async cancelForm() {
    this.$router.push(AdminRouter.DriverList);
  }

  async onProvinceChange(e: any) {
    this.districts = await metaStore.loadDistrict(e);
  }

  async onDistrictChange(e: any) {
    this.municipality = await metaStore.loadMuncipality(e);
  }
  get province() {
    return metaStore.provinceList;
  }
  get districtList() {
    return metaStore.districtL;
  }
  get gender() {
    return metaStore.genderList;
  }
  get bloodGroup() {
    return metaStore.bloodGroupList;
  }

  mounted() {}
  updated() {}
}
